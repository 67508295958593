import React from "react";
import Image from "next/legacy/image";

const stats = [
  { label: "Founded", value: "2019" },
  { label: "Birthdays Celebrated", value: "1000+" },
];

// import cake from "../public/images/cakes/peppapig1.jpg";
import cake from "../public/images/cakes/beatbox.jpeg";
import Link from "next/link";
import { Heading2, Heading3 } from "./Elements";

const HomeContent: React.FC = () => (
  <div className="relative pb-16 bg-white/25 rounded-lg">
    <div className="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-cols-2 lg:items-start lg:gap-24 lg:px-8">
      <div className="hidden lg:block relative sm:py-16 md:py-4 lg:py-0">
        <div className="relative mx-auto max-w-md px-0 sm:max-w-3xl lg:max-w-none lg:px-0 lg:py-12">
          <div className="relative overflow-hidden shadow-xl rounded-2xl">
            <Image
              src={cake}
              className="relative inset-0 h-full w-full object-cover"
              alt="Peppa Pig Cake"
              layout="responsive"
              objectFit="cover"
            />
          </div>
        </div>
      </div>

      <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">
        {/* Content area */}
        <div className="pt-4 lg:pt-16">
          <Heading2>What We Do</Heading2>
          <Heading3>Making sure every child is celebrated</Heading3>
          <div className="mt-4 space-y-6 text-gray-500">
            <p className="mt-3 max-w-2xl text-xl text-brand-navy/95">
              We work with agencies and charities to find children in the
              Waikato who may be experiencing hardship, neglect, abuse, serious
              illness or family violence.
            </p>
            <div className="lg:hidden relative sm:py-16 md:py-4 lg:py-0">
              <div className="relative mx-auto max-w-md px-0 sm:max-w-3xl lg:max-w-none lg:px-0 lg:py-12">
                <div className="relative overflow-hidden shadow-xl rounded-2xl">
                  <Image
                    src={cake}
                    className="relative inset-0 h-full w-full object-cover"
                    alt="Peppa Pig Cake"
                    layout="responsive"
                    objectFit="cover"
                  />
                </div>
              </div>
            </div>
            <p className="mt-3 max-w-2xl text-xl text-brand-navy/80">
              The Cake Detective then produces professional quality birthday
              cakes tailored just for them, so they still feel special and loved
              on their birthday.
            </p>
          </div>
        </div>

        {/* Stats section */}
        <div className="mt-10">
          <dl className="grid grid-cols-2 gap-x-4 gap-y-8">
            {stats.map((stat) => (
              <div
                key={stat.label}
                className="border-t-2 border-brand-ranga pt-6"
              >
                <dt className="text-base font-medium text-brand-navy/50">
                  {stat.label}
                </dt>
                <dd className="text-3xl font-bold tracking-tight text-gray-900">
                  {stat.value}
                </dd>
              </div>
            ))}
          </dl>
          <div className="mt-10">
            <Link
              href="/support-us"
              className="text-base font-medium text-brand-pink"
              legacyBehavior
            >
              <a>
                Find out how to support us
                <span aria-hidden="true"> &rarr;</span>
              </a>
            </Link>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default HomeContent;
