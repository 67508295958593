import type { NextPage } from "next";
import Image, { StaticImageData } from "next/legacy/image";

import Page from "@/components/Page";
import Thanks from "@/components/Thanks";

import mainBanner from "../public/images/main-banner-moved.jpeg";

import step1 from "../public/images/illustrations/step1.png";
import step2 from "../public/images/illustrations/step2.png";
import step3 from "../public/images/illustrations/step3.png";
import step4 from "../public/images/illustrations/step4.png";
import step5 from "../public/images/illustrations/step5.png";
import step6 from "../public/images/illustrations/step6.png";
import step7 from "../public/images/illustrations/step7.png";
import step8 from "../public/images/illustrations/step8.png";

import Banner from "@/components/Banner";
import { FunctionComponent } from "react";
import { ArrowTopRightOnSquareIcon as ExternalLinkIcon } from "@heroicons/react/24/outline";

import HomeContent from "@/components/Content";
import { Heading2, Heading3 } from "@/components/Elements";
import { DownloadAndroid, DownloadiOS } from "@/components/download-buttons";

const ButtercreamEffect: FunctionComponent = () => {
  return (
    <div
      id="special"
      className="relative bg-white/25 my-12 rounded-xl overflow-hidden"
    >
      <div className="relative h-56 bg-white/25 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2 overflow-hidden p-8">
        <div className="relative h-full">
          <Image
            className="w-full h-full object-contain"
            src="/images/ogk.png"
            alt=""
            layout="fill"
          />
        </div>
      </div>
      <div className="relative  mx-auto px-4 sm:px-6 py-8 lg:px-8 lg:py-12 flex items-center bg-white/25">
        <div className="md:ml-auto md:w-1/2 md:pl-10">
          <Heading2>One Good Kiwi</Heading2>
          <p className="mt-2 text-brand-navy text-3xl font-extrabold tracking-tight sm:text-4xl">
            Unleash the Goodness of Aotearoa with One Good Kiwi
          </p>
          <p className="mt-3 text-lg text-brand-navy/75">
            Connect, Support, and Empower Kiwis who are making a difference
          </p>
          <p className="mt-3 text-lg text-brand-navy/75">
            For the month of May The Cake Detective have been invited to join
            other incredible kiwi charities with the opportunity to earn a
            portion of $100,000.
          </p>
          <p className="mt-3 text-lg text-brand-navy/75">
            All you need to do is download the One Good Kiwi App and swipe your
            tokens up for The Cake Detective. There is no catch, you are giving
            10 tokens which can be swiped up to whichever charity has an impact
            on you.
          </p>
          <p className="mt-3 text-lg text-brand-navy/75">
            It is a fun way of giving using a pool of funding from One New
            Zealand (Vodafone).
          </p>
          <div className="mt-8">
            <div className="inline-flex gap-x-4 flex-wrap">
              <DownloadiOS href="https://onegoodkiwi.nz/ios" />
              <DownloadAndroid href="https://onegoodkiwi.nz/android" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
// w-full md:w-2/3 items-center flex flex-col md:flex-row-reverse
type StepProps = { step: number; img: StaticImageData };
const Step: FunctionComponent<StepProps> = ({ step, img, children }) => (
  <div
    className={`w-full lg:w-2/3 items-center flex flex-col ${
      step % 2 === 0 ? "md:flex-row-reverse" : "md:flex-row"
    }`}
  >
    <div className="w-full md:w-1/2">
      <Image
        src={img}
        className="w-56 h-full object-cover"
        alt={`Step ${step}`}
        layout="responsive"
      />
    </div>
    <div className="w-full md:w-1/2 text-center md:text-center flex flex-col justify-center items-center gap-4">
      <h6 className="bg-ranga text-white text-center text-base rounded-full leading-6 w-6 h-6">
        {step}
      </h6>
      <p className="text-xl lg:text-lg px-2 text-brand-navy/95">{children}</p>
    </div>
  </div>
);

const Home: NextPage = () => (
  <Page title="The Cake Detective">
    <Page.Header />
    <Banner banner={mainBanner}>
      <div className="container h-1/2 px-4 md:px-10 lg:px-28">
        <div className="text-white flex items-start justify-start">
          <div className="w-full sm:w-1/2">
            <h2 className="block text-xs sm:text-base uppercase text-brand-teal font-semibold tracking-wider">
              The Cake Detective
            </h2>
            <h1 className="text-3xl sm:text-5xl md:text-7xl font-extrabold text-brand-pink font-sans lowercase sm:mb-8">
              Every <span className="text-brand-ranga">Kid</span>
              <br />
              <span className="text-cream">Deserves</span>
              <br />
              <span className="text-brand-teal">Cake</span>
            </h1>
          </div>
        </div>
      </div>
    </Banner>
    <Page.Content>
      <div className="-mx-4 md:mx-8 pt-4 md:pt-12">
        <HomeContent />
        <ButtercreamEffect />
        <Thanks />
        <div className="relative pb-16 bg-white/25 rounded-lg mb-4 md:mb-12">
          <div className="text-center">
            <div className="pt-4 lg:pt-16 px-4 md:px-0">
              <Heading2>How It Works</Heading2>
              <Heading3>Closing the case on missing cakes</Heading3>
            </div>
          </div>
          <div className="my-4 flex-col items-center flex w-full px-4 gap-6">
            <Step step={1} img={step1}>
              A child going through hardship
              <br className="hidden md:inline" /> (e.g., illness, poverty,
              domestic violence) has a birthday coming up
            </Step>
            <Step step={2} img={step2}>
              A referring agency (or Social Worker, Nurse, Caregiver, Teacher)
              completes a form on our website with all the info we need
              including the cake theme and flavour
            </Step>
            <Step step={3} img={step3}>
              We confirm the cake and it goes into our system
            </Step>
            <Step step={4} img={step4}>
              The Cake Detectives design and plan the cake
            </Step>
            <Step step={5} img={step5}>
              The cake is decorated at our kitchen (
              <a
                href="https://sweetpeaparties.co.nz"
                className="text-ranga underline"
              >
                Sweetpea Parties
              </a>
              ) and is all boxed up ready to go
            </Step>
            <Step step={6} img={step6}>
              Collection information is sent to the agency or recipient
            </Step>
            <Step step={7} img={step7}>
              The cake is collected from the kitchen and includes candles to be
              blown out
            </Step>
            <Step step={8} img={step8}>
              The cake is received by tamariki and they celebrate their special
              day with their whanau
            </Step>
          </div>
        </div>
      </div>
    </Page.Content>
    <Page.Footer>
      <div className="flex justify-between">
        <p>another</p>
        <div className="text-center w-1/3 text-ranga-50">
          <p>
            <strong className="capitalize">Our Vision</strong>
            <br />
            Every child’s birthday is celebrated.
          </p>
          <p>
            <strong className="capitalize">OUR MISSION</strong>
            <br />
            To ensure every child feels uniquely celebrated on their birthday
            regardless of circumstance. We aim to do that with extraordinary
            birthday cakes.
          </p>
          <p>
            <strong className="capitalize">OUR MESSAGE</strong>
            <br />
            Recipients have often experienced neglect, abuse, serious illness or
            family violence. What these cakes do is send a message of hope, love
            and solidarity: &quot;We see you. We care about you. You matter. You
            deserve love and happiness&qout;
          </p>
        </div>
        <p>another</p>
      </div>
    </Page.Footer>
  </Page>
);

export default Home;
