import React, { FunctionComponent } from "react";

const Heading2: FunctionComponent = ({ children }) => {
  return (
    <h2 className="text-sm md:text-base font-semibold uppercase tracking-wider text-brand-ranga">
      {children}
    </h2>
  );
};

const Heading3: FunctionComponent = ({ children }) => {
  return (
    <h3 className="text-3xl font-bold tracking-tight text-brand-navy sm:text-4xl">
      {children}
    </h3>
  );
};

export { Heading2, Heading3 };
