import Image, { StaticImageData } from "next/legacy/image";

type BannerWidth = "w-full" | "w-1/2" | "w-1/3";

type BannerPosition = "fixed" | "relative" | "absolute";

type BannerOpacity = "opacity-90" | "opacity-50" | "opacity-60";

interface BannerProps {
  banner: string | StaticImageData;
  width?: BannerWidth;
  position?: BannerPosition;
  opacity?: BannerOpacity;
  aspectW?: "aspect-w-16" | "aspect-w-9" | "aspect-w-6";
  aspectH?: "aspect-h-16" | "aspect-h-9" | "aspect-h-6" | "aspect-h-4";
}

const Banner: React.FC<BannerProps> = ({
  banner,
  width = "w-full",
  position = "absolute",
  opacity = "opacity-90",
  aspectW = "aspect-w-16",
  aspectH = "aspect-h-9",
  children,
}) => {
  return (
    <div className={`relative w-full h-full ${aspectW} ${aspectH}`}>
      <div className="bg-brand-navy">
        <Image
          src={banner}
          className={`w-full object-cover object-center ${opacity}`}
          layout="fill"
          alt="The Cake Detective"
        />
      </div>
      <div className="flex items-center justify-center">{children}</div>
    </div>
  );
};

export default Banner;
